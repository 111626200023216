<template>
    <div>

      <v-text-field
        v-if="!hidesearch"
        v-model="search"
        append-icon="mdi-magnify"
        label="rechercher dans le tableau"
        single-line
        hide-details
      ></v-text-field>

    <v-data-table
        :headers="head"
        :items="data"
        :show-select="showselect"
        :single-select="false"
        v-model="selectedItem"
        :search="search"
        :item-key="keyRow"
        :hide-default-footer="hideDefaultFooter"
        :disable-pagination="hideDefaultFooter"
        class="row-pointer"
    >

      <template v-slot:body="props" v-if="isDraggable">
        <draggable
          v-model="props.items"
          tag="tbody"
          handle=".handle"
          @change=changeOrder(props)
        >
          <tr
            v-for="(ligne, i) in props.items" :key="i"
          >

            <td>
                <v-icon class="handle" style="cursor:grab;">mdi-arrow-up-down</v-icon>
                {{i+1}}
            </td>
            <template v-for="(val, i2) in head" >
            <td :key="i2" v-if="val.value!='action' && val.value!='orderTable'"> {{ ligne[val.value] }}</td>
            </template>

            <td>
            <v-icon small v-for="(act,ind) in Edit" :key="ind"               
                class="mr-2"
                @click="act.action(ligne)"
            >
                {{act.icon}}
            </v-icon>
            </td>

          </tr>
        </draggable>
      </template>

        <template v-slot:[`item.action`]="{ item }" v-if="!isDraggable">
            <v-icon small v-for="(v,i) in Edit" :key="i"               
                class="mr-2"
                @click="v.action(item)"
            >
                {{v.icon}}
            </v-icon>

        </template>

    </v-data-table>

</div>
</template>


<script>

export default {
    name : 'SimpleTable',
    props : ['Headers','datas','editTable','del','selected','keyRow','isDraggable','hidefooter','hidesearch','item'],

    components : {
        draggable    : () => import('vuedraggable'),
    },
        data: function() {
            return { 

                head : this.Headers,
                data : [],
                Delete : {icon:'mdi-delete',action:this.deleteItem},
                Edit : [],
                showselect : false,
                selectedItem : [],
                hideDefaultFooter : true,
                search : ''

            }
        },

watch : {

    datas() { 
        this.getDatas()
    },

    selectedItem(val) {

        var returnSelect = []
        var that = this
        if(val.length>0) {
            val.forEach(function(v){
                returnSelect.push(v[that.keyRow])
            })
        } 
        this.$emit('getselected',returnSelect);
    },

    editTable() {
        this.Edit = []
        this.PutActionRow()
    }

},

        methods : {

            putHeadAction() {
                this.head.push({text:'action',value:'action',sortable:false})

            },

            constructHeader() {

                this.head = this.Headers
                if(this.isDraggable) this.head.unshift({text:'ordre',value:'orderTable'})

            },

            changeOrder(props) {
                this.data = props.items
                this.$emit('recepVal',this.data);

            },

            PutActionRow() {
                var that = this;
                if(Array.isArray(this.editTable)) {
                    this.editTable.forEach(function(v){
                        that.Edit.push(v)
                    })
                }
                
                if(this.del===true) this.Edit.push(this.Delete); 
            },

            getDatas() {

                this.data = []
                if(this.datas)
                this.datas.forEach((ligne) => {
                    this.data.push(ligne)                                
              
                })

            },

            deleteItem(item) {
                if(confirm('Vous allez supprimer cette ligne, Etes-vous sur ?')) {
                this.data.splice(this.data.indexOf(item), 1);
                // permet de récupérer le nouvel array avec celui supprimé
                this.$emit('recepVal',this.data);
                }
            }, 

 
            select : function() {
                if(this.selected && this.data.length>0) {
                    var that = this
                    this.selected.forEach(function(id){

                        let result = that.data.find(el => el.id === id)

                        if(result) that.selectedItem.push(result)
                    })
                }

            }
        },




        mounted() {     
            this.putHeadAction() 
            this.PutActionRow()
            this.constructHeader()
            this.getDatas()
            if(this.selected) this.showselect = true

            if(this.hidefooter != undefined) this.hideDefaultFooter = this.hidefooter

            this.select()
     

        }


}

</script>