<template>


<div> 


  <v-menu  v-model="menu" top nudge-bottom="23" nudge-left="0" :close-on-content-click="false" v-if="items && items.length">
            <template v-slot:activator="{ on }">
              <v-btn class="secondary float-left ma-2" v-on="on" small dark icon><v-icon>mdi mdi-list-box-outline</v-icon></v-btn>
            </template>
            <v-card>
              <v-card-text class="pa-0">

                      <Table
                      :Headers="[{text:'selectionnez votre choix',value:'text'}]"
                      :datas="items"
                      :isDraggable="false"
                      :editTable="selRow"
                      :del="false"
                      :hidesearch="false"
                      :hidefooter="false"
                      />

              </v-card-text>
            </v-card>
          </v-menu>

          <v-text-field
            
            :label="item.name"
            v-model="model"
            @keyup="keyup(item,model,false)" 
            @keyup.enter="keyup(item,model,true)"
            :name="item.value"
            :type="item.format"
            
            :rules="prop.modelRules"
            v-if="item.format!='number' && prop.show"
            :outlined="prop.outlined"
            :filled="prop.filled"
            :solo="prop.solo"
            :hint="prop.description"
            :placeholder="prop.placeholder"
            :persistent-hint="prop.persistenthint"
            :disabled="prop.disabled"
            :class="prop.class"
            :clearable="prop.clearable"
            :color="prop.coloronselect"
            @click:clear="keyup(item,'')"

            :prepend-icon="(prop.iconlr=='prepend' && prop.iconinout=='out') ? prop.iconmdi : ''"
            :prepend-inner-icon="(prop.iconlr=='prepend' && prop.iconinout!='out') ? prop.iconmdi : ''"
            :append-icon="(prop.iconlr=='append' && prop.iconinout!='out') ? prop.iconmdi : ''"
            :append-outer-icon="(prop.iconlr=='append' && prop.iconinout=='out') ? prop.iconmdi : ''"            

          ></v-text-field>    

          <v-text-field
            
            :label="item.name"
            v-model.number="model"
            @keyup="keyup(item,model,false)" 
            @keyup.enter="keyup(item,model,true)"
            :name="item.value"
            :type="item.format"

            :rules="prop.modelRules"
            v-if="item.format=='number' && prop.show"
            :outlined="prop.outlined"
            :filled="prop.filled"
            :solo="prop.solo"
            :hint="prop.description"
            :placeholder="prop.placeholder"
            :persistent-hint="prop.persistenthint"
            :disabled="prop.disabled"
            :class="prop.class"
            :clearable="prop.clearable"
            :color="prop.coloronselect"

            :prepend-icon="(prop.iconlr=='prepend' && prop.iconinout=='out') ? prop.iconmdi : ''"
            :prepend-inner-icon="(prop.iconlr=='prepend' && prop.iconinout!='out') ? prop.iconmdi : ''"
            :append-icon="(prop.iconlr=='append' && prop.iconinout!='out') ? prop.iconmdi : ''"
            :append-outer-icon="(prop.iconlr=='append' && prop.iconinout=='out') ? prop.iconmdi : ''" 
          ></v-text-field>
</div>
</template>



<script>
import mixinFields from '../../mixins/Fields'

import Table from '../engine/outils/simpleTable'  

    export default {
        name : 'TextField',

        mixins : [mixinFields],

        components : {
                Table,
            },  

        data: function() {
            return { 
              menu : false,
              selRow : [{icon:'mdi-import',action:(item)=>{this.selectItem(item)}}]
            }
        },

        methods : {

          selectItem(item) {
                let defaultKey = item.default;
                this.keyup(this.item,item[defaultKey],false);
                this.model = item[defaultKey]
                this.menu = false;
            }

        },

    }

</script>