<template>
    <v-card elevation="2" shaped class="ma-1">
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Paramètres de ventilation </v-toolbar-title>
      </v-toolbar>

      <div>
        <v-tabs align="start" background-color="blue lighten-5" v-model="tab">
          <v-tab> Paramétrage </v-tab>
          <v-tab> Champs pour analyse </v-tab>
          <v-tab> Scenario </v-tab>
            <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-row>
                <v-col class="ma-1">

                      <SwitchC
                      :item="{name:'Actif',value:'active',
                      defaultprops:{show:true}}" 
                      :change="(item,model) => { displays.params.active = model }"
                      :valField="displays.params.active" />

                      <Select
                      :item="{name: 'Sur quel action elle se déclanche ?',value: 'type',liste: [{ text: 'sur update', value: 'update' },{ text: 'sur insert', value: 'insert' },{ text: 'sur delete', value: 'delete' },{ text: 'aucune action', value: 'none' }],
                      defaultprops:{ show: true, column: false, multiple:true }}"
                      :change="changeMultiple"
                      :valField="displays.params.type"
                      />
                      <TextField 
                      :item="{value:'key',name:'Clé pour appel sur API',
                      defaultprops:{show:true}}"
                      :keyup="(item,model) => { displays.params.key = model }"
                      :valField="displays.params.key" /> 
                      <TextField 
                      :item="{value:'order',name:'Ordre de passage',
                      defaultprops:{show:true},format:'number'}"
                      :keyup="(item,model) => { displays.params.order = model }"
                      :valField="displays.params.order" /> 
                      <Select
                      :item="{name: 'Quand cela va se déclancher ?',value: 'when',liste: [{ text: 'avant l\'action', value: 'before' },{ text: 'après l\'action', value: 'after' }],
                      defaultprops:{ show: true}}"
                      :change="(item,model) => { displays.params.when = model['value'] }"
                      :valField="displays.params.when"
                      />
                      <Select
                      :item="{name: 'de quelle manière souhaitez-vous déclencher l\'action ?',value: 'typeupdate',liste: [{ text: 'sur enregistrement du formulaire', value: 'form' },{ text: 'sur modif du champ (ne serv à rien pour linstant)', value: 'field' }],
                      defaultprops:{ show: true, column: false, multiple:true }}"
                      :change="changeMultiple"
                      :valField="displays.params.typeupdate"
                      />
                      <Select
                      :item="{name: 'Sur quel champ l\'action va se déclancher ?',value: 'fieldtargeted',liste: fieldsInModuleTable,
                      defaultprops:{ show: true}}"
                      :change="(item,model) => { displays.params.fieldtargeted = model['value'] }"
                      :valField="displays.params.fieldtargeted"
                      />

                </v-col>
              </v-row>
            </v-tab-item>

            <!-- Champs pour analyse-->
            <v-tab-item>
              <Fields :table="module.table" :champs="displays.params.fieldanalyse" :filter="[]" :page="page"
                                @recepVal="(array) => { displays.params.fieldanalyse = array }"/>
            </v-tab-item>


            <v-tab-item>
              Vous pouvez créer un scénario avec des conditions et des actions. Vous pouvez aussi interagir avec l'utilisateur pour confirmer qu'une action va bien être lancé.
              Pour commencer, veuillez saisir une action ou une condition.

              <span v-if="selectedVariable"> [[ventilation||{{ selectedVariable }}]]</span>
              <Cventilation :page="page" :table="module.table" :conditions="displays.params.scenario" type="condition" />
            </v-tab-item>

          </v-tabs-items>
        </v-tabs>
    </div>
    </v-card>
  </template>
  
  
  
  <script>
  export default {
    name: "VentilationModule",
    props: ["module", "page","itemTableListe"],
  
    components: {
        /*
      Autocomplete: () => import("../../fields/autocomplete.vue"),
      autocomplete: () => import("../../fields/autocomplete.vue"),
      Filtre: () => import("../outils/constructFiltre/Filtre.vue"),
      
      ArrayTable: () => import("../../fields/arrayTable.vue"),
      texteditor : () => import("../../fields/texteditor.vue"),
      
        */
        Fields: () => import("../outils/constructField/Field.vue"),
        Select: () => import("../../fields/select.vue"),
        SwitchC: () => import("../../fields/switch.vue"),
        TextField: () => import("../../fields/textfield.vue"),
        Cventilation: () => import("../outils/constructVentilation/Ventilation.vue"),
    },
  
    data: function () {
      return {
        tab : '',
        tab2 : '',
        displays: this.module.displays,
        fieldsInModuleTable : [],
        selectedVariable : ''

      };
    },
  
    watch: {

  },
  
    methods: {

      changeMultiple(item,model) {
        var realValue = [];
          for(var selected in model) {
            if(typeof model[selected] == 'object') { realValue.push(model[selected]['value']) } else { realValue.push(model[selected]); }
          }
        this.displays.params[item.value] = realValue;
      }
  
    },
  
    mounted() {
 
      this.getListField({filter:{table:this.module.table}}).then((resp) => {   
                    resp.forEach((field) => {
                        this.fieldsInModuleTable.push({text:field.field+" ("+field.cle+")",value:field.cle,default:'value'})
                    })                          
             })
    },
  };
  </script>
  
  