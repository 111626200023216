<template>
    <v-card elevation="2" shaped class="ma-1">
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Paramètres d'Envoi d'email </v-toolbar-title>
      </v-toolbar>

      <div>
        <v-tabs align="start" background-color="blue lighten-5" v-model="tab">
          <v-tab> Corp du mail </v-tab>
          <v-tab> requete pour e-mails et crochets </v-tab>
          <v-tab> Documents attachés </v-tab>
          <v-tab> Action sur envoi </v-tab>
          <v-tabs-items v-model="tab" class="pl-4">

            <v-tab-item>
              <v-row>
                <v-col class="ma-1">
                    Définissez le corp et l'objet du mail. Soit à partir de champs d'une table, soit directement dans le module
                    <Select
                    :item="{name: 'Quel corp de mail allez-vous choisir ?',value: 'type',liste: [{ text: 'sélectionner', value: '' },{ text: 'Construire mon mail dans ce module', value: 'manual' },{ text: 'Choisir à partir d\'un élément d\'une table', value: 'fromfield' }],
                    defaultprops:{ show: true, column: false }}"
                    :change="cc"
                    :valField="displays.params.type"
                    />


                    <div v-if="displays.params.type == 'manual'">

                <Autocomplete
                :item="{name: 'Sélectionnez la page qui fera l\'objet de la bibliothèque dans l\'éditeur',value: 'type',liste: listPages,
                defaultprops:{ show: true }}"
                type="form"
                :change="(item,value,select) => { displays.params.manual.pagebibli = value[select]; $emit('recepVal',displays) }"
                :valField="displays.params.manual.pagebibli"
                />

                    <texteditor
                    v-if="displays.params.manual.pagebibli"
                    :page="page"
                    :item="{name: 'corp du mail',value: 'corp',
                    
                    defaultprops:{ show: true},
                    otherparams:{menueditor:[{namelist:'élements','icon':'',list:[{type:'menuitem','text':'médias',link:displays.params.manual.pagebibli}]}]}
                    }"
                    :keyup="(item,value) => { displays.params.manual.corp = value }"
                    :valField="displays.params.manual.corp"
                    />
                    </div>

                    <div v-if="displays.params.type == 'fromfield'">
                        <Autocomplete
                            :item="{ 
                                name: 'Sélectionnez la table sujete au corp du mail', 
                                value: 'table', 'liste': itemTableListe,
                                defaultprops:{ show: true, chips: false, deletablechips: false, modelRules: [v => !!v || 'Ce champ doit etre renseigné'] }
                            }" :valField="displays.params.fromfield.table" :change="(item,value,select) => { displays.params.fromfield.table = value[select]; $emit('recepVal',displays) }" />  
                            

                        <v-tabs align="start" :key="displays.params.fromfield.table" background-color="blue lighten-5" v-model="tab2" v-if="displays.params.fromfield.table">
                        <v-tab> Filtres </v-tab>
                        <v-tab> Champs </v-tab>
                        <v-tabs-items v-model="tab2" class="pl-4">
                            <v-tab-item>

                                <Filtre                              
                                :filtre="displays.params.fromfield.filter"
                                :table="displays.params.fromfield.table"
                                @recepVal="(data) => {displays.params.fromfield.filter = data; $emit('recepVal',displays)}"
                                />
                            </v-tab-item>

                            <v-tab-item>
                                <p>Vous devez choisir l'objet, le corp et le titre du mail en nommant les valeurs du as en "objet", "corp" et "titre"</p>
                                <Fields
                                :table="displays.params.fromfield.table"
                                :champs="displays.params.fromfield.champs"
                                :filter="displays.params.fromfield.filter"
                                :page="page"
                                @recepVal="(data) => {displays.params.fromfield.champs = data; $emit('recepVal',displays)}"
                                />
                            </v-tab-item>
                        </v-tabs-items>
                        </v-tabs>

                    </div>


                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>

              <v-tabs align="start" background-color="blue lighten-5" class="ma-3" v-model="tabAff" v-if="displays.params.fromfield.table">
                        <v-tab> Filtres </v-tab>
                        <v-tab> Champs </v-tab>
                        <v-tab> Adresses E-mail </v-tab>
                        <v-tabs-items v-model="tabAff" class="pl-4">
                            <v-tab-item>
                              Le filtre va permettre de construire la requete pour affilier les crochets situés dans le corp du mail afin de les remplacer par les valeurs de la requete
                                <Filtre
                                  :filtre="displays.filter"
                                  :table="module.table"
                                  @recepVal="changeFilter"
                                />
                            </v-tab-item>

                            <v-tab-item>
                              <p class="font-weight-medium">Veuillez nommer le AS des champs au nom des crochets pour affiliation. ex : {_nom_} = nom</p>

                              <v-btn @click="recupHooks">Récupérer les crochets pour affiliation</v-btn>

                              <div v-for="(list,i) in listHooks" :key="i" :class="['font-weight-bold',list.valid ? 'green--text':'red--text']"><span v-if="!list.valid">Affilier le crochet</span> {{ list.hook }} <span></span></div>

                              <Fields
                              :table="module.table"
                              :champs="displays.params.champs"
                              :filter="displays.filter"
                              :page="page"
                              @recepVal="(data) => {displays.params.champs = data; $emit('recepVal',displays); verifySync()}"
                              />
                            </v-tab-item>
                            <v-tab-item>
                              <p class="font-weight-medium">Vous pouvez intégrer une variable de requete par [[email||mon_AS]]</p>
                              
                              <TextField
                              :item="{ name: 'nom de l\'expéditeur', value: 'name' ,defaultprops:{ show: true }}"
                              :valField="displays.params.emailfrom.name"
                              :keyup="(item,model)=> { displays.params.emailfrom.name = model; $emit('recepVal',displays); }"
                              /> 
                              <TextField
                              :item="{ name: 'email de l\'expéditeur', value: 'from' ,defaultprops:{ show: true }}"
                              :valField="displays.params.emailfrom.from"
                              :keyup="(item,model)=> { displays.params.emailfrom.from = model; $emit('recepVal',displays); }"
                              /> 
                              <ArrayTable 
                                :item="{otherparams:{
                                    arrayTable:[
                                        {type:'',key:'adresse',name:'adresse e-mail'},                           
                                        {type:'',key:'alias',name:'alias'},
                                        {type:'select',key:'type',name:'emplacement',ArrayValue:[{text:'A',value:'a'},{text:'Cc',value:'cc'},{text:'Bcc',value:'bcc'}]}]}
                                ,defaultprops:{show:true,tableDraggable:false,tableDel:true,hideFooter:true,hidesearch:true,addTitle:'ajouter une adresse de destination'}}"
                                :valField="displays.params.emails"
                                :change="(item,value) => {displays.params.emails = value; $emit('recepVal',displays);}"
                                />
                            </v-tab-item>
                        </v-tabs-items>
                        </v-tabs>





            </v-tab-item>
          <v-tab-item>
            <ArrayTable 
                    :item="{otherparams:{
                        arrayTable:[
                        {type:'select',key:'type',change:checkModule,name:'Type de document',ArrayValue:listDocAttach},
                            {type:'select',key:'module',name:'Module concerné',ArrayValue:listModule},                           
                            {type:'arraytable',key:'params',name:'Paramètres pour le module',
                              defaultprops:{show:true,tableDraggable:false,tableDel:true,hideFooter:true,hidesearch:true,addTitle:'ajout du paramètre'},
                                otherparams:{
                                  arrayTable:[
                                  {type:'',key:'key',name:'nom de la clé'},
                                  {type:'',key:'value',name:'Valeur de la clé',description:'Vous pouvez utiliser les variables [[params||ma_var]]'}
                                  ]
                                }

                          },
                          {type:'arraytable',key:'includecond',name:'Conditions d\'inclusion',
                              defaultprops:{show:true,tableDraggable:false,tableDel:true,hideFooter:true,hidesearch:true,addTitle:'ajout condition'},
                                otherparams:{
                                  arrayTable:[
                                  {type:'',key:'key',name:'si la valeur',description:'peut intégrer la variable email : [[email||mon_as]]'},
                                  {type:'select',key:'condition',name:'condition',ArrayValue:[{text:'est égal à',value:'='},{text:'est différent de',value:'!='},{text:'contient',value:'contein'}]},
                                  {type:'',key:'value',name:'Cette valeur',description:'Vous pouvez utiliser les variables [[params||ma_var]]'}
                                  ]
                                }

                          }]}
                    ,defaultprops:{show:true,tableDraggable:false,tableDel:true,hideFooter:true,hidesearch:true,addTitle:'ajouter un document d\'attachement'}}"
                    :valField="displays.params.attachement"
                    :change="(item,value) => {displays.params.attachement = value; $emit('recepVal',displays);}"
                    />
          </v-tab-item>


          <v-tab-item>
              <TextField
                :item="{ name: 'Nom du bouton d\'envoi', value: 'name' ,defaultprops:{ show: true }}"
                :valField="displays.params.button.name"
                :keyup="(item,model)=> { displays.params.button.name = model; $emit('recepVal',displays); }"
                /> 
              <TextField
              :item="{ name: 'classe du bouton', value: 'class' ,defaultprops:{ show: true }}"
              :valField="displays.params.button.class"
              :keyup="(item,model)=> { displays.params.button.class = model; $emit('recepVal',displays); }"
              /> 
            </v-tab-item>

          </v-tabs-items>
        </v-tabs>
    </div>
    </v-card>
  </template>
  
  
  
  <script>
  export default {
    name: "SendEmail",
    props: ["module", "page","itemTableListe"],
  
    components: {
      Autocomplete: () => import("../../fields/autocomplete.vue"),
      Select: () => import("../../fields/select.vue"),
      Filtre: () => import("../outils/constructFiltre/Filtre.vue"),
      Fields: () => import("../outils/constructField/Field.vue"),
      ArrayTable: () => import("../../fields/arrayTable.vue"),
      texteditor : () => import("../../fields/texteditor.vue"),
      TextField: () => import("../../fields/textfield.vue"),

    },
  
    data: function () {
      return {

        displays: this.module.displays,
        typegenerate : '',
        manual : '',
        reload : 0,
        tab : null,
        tab2 : null,
        tabAff : null,
        listPages : [],
        listFields : [],
        listHooks : [],
        listModule : [],
        listDocAttach : [{text:'Module PDF',value:'pdf'},{text:'Document interne',value:'docinterne'},{text:'Document GED',value:'ged'}],

      };
    },
  
    watch: {

  },
  
    methods: {

      checkModule(item,value,selectedKey) {
        if(value[selectedKey]=='ged' || value[selectedKey]=='pdf') {
          this.listModule = []
          this.API({ type: 'post', url: "/engine/module/list", params: { type: value[selectedKey] } }).then((resp) => {
            resp.data.forEach((v) => {
              this.listModule.push({ value: v.id, text: v.name });
            });
          })
        }

      },

        cc(item,value,selectedKey) {
            this.displays.params[item.value] = value[selectedKey]
            this.$emit('recepVal',this.displays)
        },

        recupHooks() {
          this.listHooks = []
          if(this.displays.params.type=='fromfield') {
            let isCorp = this.displays.params.fromfield.champs.find((el) => el.as =='corp')
            if(!this.displays.params.fromfield.table && !isCorp) {
                this.$toast.warning('Veuillez sélectionner la table et le corp dans l\'onglet corp de mail');
                return
            }

            this.getListField({filter:{table:this.displays.params.fromfield.table}}).then((resp) => {    
                var getVars = [] 
                
                            resp.forEach((field) => {
                                if(field.table+"."+field.field == isCorp.field) {
                                  // on update l'id du champ qui fait l'objet du corp, ca permettra de sortir ce champ si l'user veut modifier avant d'envoyer
                                  this.displays.params.fromfield.idField = field.id
                                  this.$emit('recepVal',this.displays)
                                    getVars = field.displays.otherparams.menueditor
                                    getVars.forEach((groupList) => {
                                        groupList.list.forEach((elem) => {
                                            if(elem.value!==null && elem.value!='') this.listHooks.push({hook:elem.value,valid:false})
                                        })
                                    })
                                }
                            })
                            this.verifySync();                                                                                                      
                        })
          }
          
          if(this.displays.params.type=='manual') {
            const resultats = this.displays.params.manual.corp.match(/\{_(.*?)_\}/g);
            resultats.forEach((elem) => { this.listHooks.push({hook:elem,valid:false}) })
          }

        },
        
        verifySync() {
            this.listHooks.forEach((hook,h)=> {
                this.listHooks[h].valid = false;
                let name = hook.hook.match(/{_([^}]+)_}/)[1];
                if(this.displays.params.champs.find((el) =>  el.as == name)) this.listHooks[h].valid = true;
            })
        },

      changeFilter(array) {
      this.displays.filter = array
      this.$emit('recepVal',this.displays)
    },

    changeEC(array) {
      this.displays.params.champs = array
      this.$emit('recepVal',this.displays)     
    },
  
    },
  
    mounted() {
            //permet de cibler la page pour lien de photo :
            this.$store.getters.listAll.forEach((page)=> {
                this.listPages.push({text:page.displayname+" - "+page.path,value:page.name})
            })  
  
    },
  };
  </script>
  
  