<template>
    <v-card elevation="2" shaped class="ma-1">
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Paramètres de la bibliothèque </v-toolbar-title>
      </v-toolbar>

      <div>

        <v-tabs align="start" background-color="blue lighten-5" v-model="tab">
          <v-tab> Paramètres généraux </v-tab>
          <v-tab> requetes </v-tab>
          <v-tab> Champs </v-tab>
          <v-tab> Actions </v-tab>
          <v-tabs-items v-model="tab" class="pl-4">

            <v-tab-item>
              <v-row>
                <v-col class="ma-1">
                  <TextField
                    :item="{ name: 'Catégorie', value: 'categorie' ,defaultprops:{ show: true }}"
                    :valField="displays.params.categorie"
                    :keyup="(item,model,selectedKey)=> { displays.params.categorie = model; $emit('recepVal',displays); }"
                    /> 
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
                Vous devez obligatoirement affilier un champ de la table principale à la table "bibliotheques" pour récupérer les informations de l'image
              <Filtre
                :filtre="displays.filter"
                :table="module.table"
                @recepVal="changeFilter"
              />
            </v-tab-item>

          <v-tab-item>
            <p>Vous devez sélectionner un champ avec comme AS "name" pour que l'image soit nommé dans la bibliothèque.</p>
            <p>Pour intéragir avec les actions du module, veuille à bien insérer les valeurs pour l'interaction</p>
            <Fields
              :table="module.table"
              :champs="displays.params.champs"
              :filter="displays.filter"
              :page="page"
              @recepVal="changeEC"
              />

              <div class="text-h6">champs selectionnés pour la recherche globale</div>
              <Autocomplete :item="{
                name: 'Champs ciblés pour recherche globale',
                value: 'globalsearch',
                defaultprops: { show: true, multiple: true },
                liste: listFields,
              }" :valField="displays.params.globalsearch" :change="changeGlobalSearch" />
          </v-tab-item>

          <v-tab-item>

            <listActions :page="page" :module="module" :key="reload"/>

            </v-tab-item>

          </v-tabs-items>
        </v-tabs>
    </div>
    </v-card>
  </template>
  
  
  
  <script>
  export default {
    name: "BibliothequeModule",
    props: ["module", "page"],
  
    components: {
      Autocomplete: () => import("../../fields/autocomplete.vue"),
      Filtre: () => import("../outils/constructFiltre/Filtre.vue"),
      Fields: () => import("../outils/constructField/Field.vue"),
      TextField: () => import("../../fields/textfield.vue"),
      listActions: () => import("../outils/listActions.vue")

    },
  
    data: function () {
      return {

        displays: this.module.displays,
        typegenerate : '',
        manual : '',
        reload : 0,
        tab : null,
        listFields : []

      };
    },
  
    watch: {
    showMod: {
      // permet de rafraichir quand la modal de la page se ferme
      handler(val) {
        if (val.show === false) {
          this.reload++;
        }

      },
      deep: true,
    },

  },
  
    methods: {

      generate() {
        this.API({type:'post', url:'pdf/generatefile', params:{id:this.module.id, type:this.typegenerate,manual : this.manual}}).then(() => {

                    })
      },

      changeFilter(array) {
      this.displays.filter = array
      this.$emit('recepVal',this.displays)
    },

    changeEC(array) {
      this.displays.params.champs = array
      this.$emit('recepVal',this.displays)     
    },

    getFields() {

      this.API({ type: 'post', url: 'engine/module/getfieldswithjunctions', params: {table:this.module.table,filter:this.displays.filter} }).then((resp) => {
          this.Fields = resp.data
                  resp.data.forEach((field) => {
                    this.listFields.push({ 'text': field.table + '.'+field.field, value:field.cle })
                  })
          })

      },

      changeGlobalSearch(item, value, selectedKey) {
      this.displays.params.globalsearch = [];
      for (var selected in value) {
        if (typeof value[selected] == 'object') { this.displays.params.globalsearch.push(value[selected][selectedKey]) } else { this.displays.params.globalsearch.push(value[selected]); }
      }
      this.$emit('recepVal', this.displays)
    },
  
    },
  
    mounted() {
      this.getFields()
    },
  };
  </script>
  
  