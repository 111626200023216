<template>
    <div>
      <v-select
        :items="items"
        :item-text="(prop.itemtext)?prop.itemtext:'text'"
        :item-value="defaultValue"
        return-object
        :label="item.name"
        v-model="model"
        @change="change(item, model, defaultValue)"
        :name="item.value"
        :multiple="prop.multiple"

        :rules="prop.modelRules"
        v-if="prop.show"
        :outlined="prop.outlined"
        :filled="prop.filled"
        :solo="prop.solo"
        :hint="prop.description"
        :persistent-hint="prop.persistenthint"
        :disabled="prop.disabled"
        :class="prop.class"
        :clearable="prop.clearable"
        :color="prop.coloronselect"
        @click:clear="change(item,'',defaultValue)"
            :prepend-icon="(addIcon && positionAddIcon=='leftout') ? addIcon : ''"
            :prepend-inner-icon="(addIcon && positionAddIcon=='leftin') ? addIcon : ''"
            :append-outer-icon="(addIcon && positionAddIcon=='rightout') ? addIcon : ''"
            :append-icon="(addIcon && positionAddIcon=='rightin') ? addIcon : ''"

            @click:prepend="clickOnPosition('prepend')" 
            @click:prepend-inner="clickOnPosition('prepend-inner')" 
            @click:append="clickOnPosition('append')" 
            @click:append-outer="clickOnPosition('append-outer')"            
      >
    
    </v-select>
    </div>
  </template>
  
<script>

import mixinFields from '../../mixins/Fields'

    export default {

      name: "TextField",

      mixins : [mixinFields],

      // item étant l'array du champ avec tous ces parametres, module = numero du module, list
      data: function () {
        return {

        };
      },

      computed: {
        // on fait un computed aec JSON.parse sur this.item afin que sur le watch, il y ait les valeur new et old. 
        // sinon on ne peut pas chécker la différence d'un nested array avec new et old dans le watch
        CheckListe () {
          return JSON.parse(JSON.stringify(this.item))
        }
      },

      watch : {

        CheckListe : {
            handler(val,old) {
                  if(JSON.stringify(val.liste)!=JSON.stringify(old.liste)) {
                  this.items = val.liste                  
              }
            },deep :true
           },        
      },

    
      methods: {
        

      },

      mounted() {

      }

    }

</script>
  
  