
<template> 
  <div>

                <v-tabs v-model="tab">
                    <v-tab>Groupes</v-tab>
                    <v-tab>Individus</v-tab>
                    <v-tabs-items v-model="tab" class="pa-2">

                        <v-tab-item>
                         
                          <attribDroit :datas="datasGroup" :users="datasPerso.listType" type="groupe" :page="page" :args="args" @reload="$emit('reload')"/>

                        </v-tab-item>

                        <v-tab-item>
                          
                         
                          <attribDroit :datas="datasPerso" type="perso" :args="args" @reload="$emit('reload')"/>
                        </v-tab-item>

                    </v-tabs-items>
                </v-tabs>              

  </div>
</template>



<script>

import attribDroit from './attribDroit'
export default {
    name:'routingDroits',

    components : {
            attribDroit
        },

    props : ['page','modal'],

        data: function() {
            return {
              tab : 'tabs',
              args : {}   ,
              datasGroup : [], 
              datasPerso : []        
            }
        },

        watch : {

          showMod: {
            // permet de rafraichir quand la modal de la page se ferme
            handler(val) {
              console.log(val.show)
                if (val.show === false && val.propsPage.name=='edittype_droits') {
                  //permet de rafraichir l'engine droits
                  this.$emit('reload')
                }
            }, deep: true

          }

        },
        methods : {
        
        },
        
            mounted() {

              this.args = (this.modal && this.$store.getters.modalShowParams({type:'to',val:this.page}).show) ? this.$store.getters.modalShowParams({type:'to',val:this.page}).params : this.$route.params
              
              var argsGroupe = Object.assign({}, this.args);
              var argsPerso = Object.assign({}, this.args);

              argsGroupe.type = 'groupe';

                  this.API({type:'post', url:'engine/droits/init',params:{id:this.idmodule,params:argsGroupe}}).then((resp) => {
                  this.datasGroup = resp.data
                  var titlePage = this.$store.getters.modalShowParams({type:'to',val:this.page}).propsPage.displayname+' '+resp.data.titlePage;
                  this.$store.dispatch('changeOptionsModal', { modal: { type: 'to', val: this.page }, type: 'propsPage', key:'displayname', value: titlePage });
                  })

              argsPerso.type = 'perso';

                this.API({type:'post', url:'engine/droits/init',params:{id:this.idmodule,params:argsPerso}}).then((resp) => {
                this.datasPerso = resp.data
                })
    
            }

}
</script>
