<template>
    <div>
    
        <not-allowed v-if="!AllowedDisplay" :page="page" :module="idmodule" :data="datas"></not-allowed>
    <v-scale-transition>

    <div v-show="show">
    
        </div>
    
    </v-scale-transition>
    </div>
    </template>
    
    
    
    <script>

  
        export default {
        props: ['idmodule','page','modal','parent'],
    
            data: function() {
    
                return {
                    AllowedDisplay : true,
                    show : false,
                    pdfUrl : '',
                    datas : [],
                    params:{},
                    querys:{}
                }
            },

        computed : {
 
        },

        watch : {


        },

            methods :  {    
                getModule : function() {
                    this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,params:this.params,querys:this.querys}}).then((resp) => {

                            if(!resp.data.success) {
                            this.AllowedDisplay = false; 
                            return false;
                            }
                            this.$store.dispatch('setFrontDatas',{datas:resp.data.datas,module:this.idmodule});
                            this.show = true 
                            
                    })
    
                },  
                
            },
            mounted() {
                var modal = this.$store.getters.modalShowParams({ type: 'to', val: this.page })
                this.params = (this.modal && modal.show) ? modal.params : this.$route.params

                this.querys = (this.modal && modal.show) ? modal.query : this.$route.query
                this.getModule()
            },
    
            created() {  

            }
    
        }
    
    </script>
    