<template>

 <v-container fluid>
            <v-radio-group
            v-model="model"
            @change="change(item,model)"
            :hide-details="prop.hidedetails"
            mandatory
            :column="prop.column" 

            :rules="prop.modelRules"
            v-if="prop.show"
            :outlined="prop.outlined"
            :filled="prop.filled"
            :solo="prop.solo"
            :hint="prop.description"
            :persistent-hint="prop.persistenthint"
            :disabled="prop.disabled"
            :class="prop.class"
            :clearable="prop.clearable"
            :color="prop.coloronselect"
  
            :prepend-icon="(prop.iconlr=='prepend' && prop.iconinout=='out') ? prop.iconmdi : ''"
            :prepend-inner-icon="(prop.iconlr=='prepend' && prop.iconinout!='out') ? prop.iconmdi : ''"
            :append-icon="(prop.iconlr=='append' && prop.iconinout!='out') ? prop.iconmdi : ''"
            :append-outer-icon="(prop.iconlr=='append' && prop.iconinout=='out') ? prop.iconmdi : ''" 
            >
      <template v-slot:label>
        <div>{{item.name}}</div>
      </template>

              <v-radio v-for="(radio, index) in item.liste" :key="index"
                :value="radio.value"
                :class="prop.class"
              >
                <template v-slot:label>
                <div>{{radio.text}}</div>
                </template>              
              </v-radio>

            </v-radio-group>     
</v-container>

</template>



<script>
import mixinFields from '../../mixins/Fields'
    export default {
        name : 'RadioField',

        mixins : [mixinFields],

        data: function() {
            return { 
            }
        },

        watch : { 

           valField(val) {
            
               this.model = val
               this.changeStoreOnField({item:this.item,model:this.model,page:this.page})
           }

        },

        methods : {

        },

    }

</script>

