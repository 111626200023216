var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.checkDroits)?_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"text-h5 text-center"},[_vm._v("Gestion des Bases de Données")])],1),_c('v-tabs',[_c('v-tab',[_vm._v("Créer une BDD entreprise")]),_c('v-tab',[_vm._v("Créer un Métier")]),_c('v-tab',[_vm._v("Créer une nouvelle table")]),_c('v-tab-item',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-h5"},[_vm._v("Créer une BDD entreprise :")]),_c('v-form',{ref:"databaseForm",model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',[_c('v-col',[_c('TextField',{attrs:{"item":{
                      value: 'entrepriseName',
                      name: 'Nom de l\'entreprise',
                      defaultprops: {
                        show: true,
                        modelRules: [
                          (v) => !!v || 'Ce champ ne doit pas être vide',
                        ],
                        outlined: true,
                        required: true,
                      },
                    },"keyup":_vm.keyUp,"valField":_vm.form.entrepriseName}}),_c('Select',{attrs:{"change":_vm.change,"item":{
                      name: 'Métier attribué',
                      value: 'databaseType',
                      liste: _vm.databaseTypes,
                      defaultprops: {
                        show: true,
                        required: true,
                        modelRules: [
                          (v) => !!v || 'Ce champ ne doit pas être vide',
                        ],
                      },
                    },"valField":_vm.form.databaseType}}),_c('v-btn',{on:{"click":_vm.createDatabaseEnt}},[_vm._v("Créer la Base de Données")])],1)],1)],1),(_vm.progressVisible)?_c('div',[_c('div',[_vm._v("Étape: "+_vm._s(_vm.step))]),_c('v-progress-linear',{attrs:{"indeterminate":true}})],1):_vm._e()],1)],1)],1),_c('v-tab-item',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-h5"},[_vm._v("Créer un Métier :")]),_c('v-form',{ref:"metierForm",model:{value:(_vm.validFormMetier),callback:function ($$v) {_vm.validFormMetier=$$v},expression:"validFormMetier"}},[_c('v-row',[_c('v-col',[_c('TextField',{attrs:{"item":{
                      value: 'metierName',
                      name: 'Nom du métier',
                      defaultprops: {
                        show: true,
                        modelRules: [
                          (v) => !!v || 'Ce champ ne doit pas être vide',
                        ],
                        outlined: true,
                        required: true,
                      },
                    },"keyup":_vm.keyUpMetier,"valField":_vm.formMetier.metierName}}),_c('v-btn',{on:{"click":_vm.createMetier}},[_vm._v("Créer le Métier")])],1)],1)],1)],1)],1)],1),_c('v-tab-item',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-h5"},[_vm._v(" Créer une nouvelle table dans le métier : ")]),_c('v-form',{ref:"migrationForm",on:{"submit":function($event){$event.preventDefault();return _vm.handleMigrationSubmit.apply(null, arguments)}},model:{value:(_vm.validFormMigration),callback:function ($$v) {_vm.validFormMigration=$$v},expression:"validFormMigration"}},[_vm._l((_vm.tables),function(table,index){return _c('v-row',{key:index},[_c('v-col',[_c('v-text-field',{attrs:{"label":"Nom de la table","rules":[
                      (v) => !!v || 'Ce champ ne doit pas être vide',
                      (v) =>
                        !_vm.existingTables.includes(v) ||
                        'Le nom de la table existe déjà',
                    ],"required":"","outlined":""},model:{value:(table.name),callback:function ($$v) {_vm.$set(table, "name", $$v)},expression:"table.name"}}),_c('v-btn',{on:{"click":function($event){$event.preventDefault();return _vm.addColumn(index)}}},[_vm._v("Ajouter une colonne")]),_c('v-row',_vm._l((table.columns),function(column,colIndex){return _c('v-col',{key:colIndex,staticClass:"d-flex",attrs:{"cols":"8","sm":"4","md":"2"}},[_c('v-card',{staticClass:"pa-2 flex-grow-1"},[_c('v-btn',{staticClass:"mb-2",attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){return _vm.removeColumn(index, colIndex)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-text-field',{attrs:{"label":"Nom de la colonne","rules":[
                            (v) => !!v || 'Ce champ ne doit pas être vide',
                            (v) =>
                              !_vm.isDuplicateColumnName(index, v) ||
                              'Ce nom de colonne existe déjà dans cette table',
                          ],"required":"","outlined":""},model:{value:(column.name),callback:function ($$v) {_vm.$set(column, "name", $$v)},expression:"column.name"}}),_c('v-select',{attrs:{"items":_vm.columnTypes,"label":"Type","rules":[
                            (v) => !!v || 'Ce champ ne doit pas être vide',
                          ],"required":"","outlined":""},model:{value:(column.type),callback:function ($$v) {_vm.$set(column, "type", $$v)},expression:"column.type"}}),_c('v-checkbox',{attrs:{"label":"Nullable","outlined":""},model:{value:(column.nullable),callback:function ($$v) {_vm.$set(column, "nullable", $$v)},expression:"column.nullable"}}),_c('v-text-field',{attrs:{"label":"Valeur par défaut","outlined":""},model:{value:(column.default),callback:function ($$v) {_vm.$set(column, "default", $$v)},expression:"column.default"}})],1)],1)}),1)],1)],1)}),_c('v-btn',{on:{"click":function($event){$event.preventDefault();return _vm.addTable.apply(null, arguments)}}},[_vm._v("Ajouter une table")]),_c('v-btn',{attrs:{"type":"submit"}},[_vm._v("Générer les Migrations")])],2)],1)],1)],1)],1),_c('v-row',[_c('v-col',[(_vm.progressVisibleMetier)?_c('div',[_c('div',[_vm._v("Étape: "+_vm._s(_vm.stepMetier))]),_c('v-progress-linear',{attrs:{"indeterminate":true}})],1):_vm._e()])],1)],1):_c('v-container',[_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(_vm.isAllow)+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }