var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-1",attrs:{"elevation":"2","shaped":""}},[_c('v-toolbar',{attrs:{"flat":"","color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v("Paramètres de ventilation ")])],1),_c('div',[_c('v-tabs',{attrs:{"align":"start","background-color":"blue lighten-5"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" Paramétrage ")]),_c('v-tab',[_vm._v(" Champs pour analyse ")]),_c('v-tab',[_vm._v(" Scenario ")]),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-row',[_c('v-col',{staticClass:"ma-1"},[_c('SwitchC',{attrs:{"item":{name:'Actif',value:'active',
                  defaultprops:{show:true}},"change":(item,model) => { _vm.displays.params.active = model },"valField":_vm.displays.params.active}}),_c('Select',{attrs:{"item":{name: 'Sur quel action elle se déclanche ?',value: 'type',liste: [{ text: 'sur update', value: 'update' },{ text: 'sur insert', value: 'insert' },{ text: 'sur delete', value: 'delete' },{ text: 'aucune action', value: 'none' }],
                  defaultprops:{ show: true, column: false, multiple:true }},"change":_vm.changeMultiple,"valField":_vm.displays.params.type}}),_c('TextField',{attrs:{"item":{value:'key',name:'Clé pour appel sur API',
                  defaultprops:{show:true}},"keyup":(item,model) => { _vm.displays.params.key = model },"valField":_vm.displays.params.key}}),_c('TextField',{attrs:{"item":{value:'order',name:'Ordre de passage',
                  defaultprops:{show:true},format:'number'},"keyup":(item,model) => { _vm.displays.params.order = model },"valField":_vm.displays.params.order}}),_c('Select',{attrs:{"item":{name: 'Quand cela va se déclancher ?',value: 'when',liste: [{ text: 'avant l\'action', value: 'before' },{ text: 'après l\'action', value: 'after' }],
                  defaultprops:{ show: true}},"change":(item,model) => { _vm.displays.params.when = model['value'] },"valField":_vm.displays.params.when}}),_c('Select',{attrs:{"item":{name: 'de quelle manière souhaitez-vous déclencher l\'action ?',value: 'typeupdate',liste: [{ text: 'sur enregistrement du formulaire', value: 'form' },{ text: 'sur modif du champ (ne serv à rien pour linstant)', value: 'field' }],
                  defaultprops:{ show: true, column: false, multiple:true }},"change":_vm.changeMultiple,"valField":_vm.displays.params.typeupdate}}),_c('Select',{attrs:{"item":{name: 'Sur quel champ l\'action va se déclancher ?',value: 'fieldtargeted',liste: _vm.fieldsInModuleTable,
                  defaultprops:{ show: true}},"change":(item,model) => { _vm.displays.params.fieldtargeted = model['value'] },"valField":_vm.displays.params.fieldtargeted}})],1)],1)],1),_c('v-tab-item',[_c('Fields',{attrs:{"table":_vm.module.table,"champs":_vm.displays.params.fieldanalyse,"filter":[],"page":_vm.page},on:{"recepVal":(array) => { _vm.displays.params.fieldanalyse = array }}})],1),_c('v-tab-item',[_vm._v(" Vous pouvez créer un scénario avec des conditions et des actions. Vous pouvez aussi interagir avec l'utilisateur pour confirmer qu'une action va bien être lancé. Pour commencer, veuillez saisir une action ou une condition. "),(_vm.selectedVariable)?_c('span',[_vm._v(" [[ventilation||"+_vm._s(_vm.selectedVariable)+"]]")]):_vm._e(),_c('Cventilation',{attrs:{"page":_vm.page,"table":_vm.module.table,"conditions":_vm.displays.params.scenario,"type":"condition"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }