import moment from "moment";

const conditionForm = {
    state: {

        specificCondition: [],

    },

    getters: {

        getSpecificCondition: (state) => (id) => {
            return state.specificCondition.find(specific => specific.Formulaire === id)
        },

    },

    mutations: {


        applySpecificCondition(state, { params, getters }) {

            let listFields = getters.listForm(params.page)['fields'];

            var getElements = state.specificCondition.find(el => el.Formulaire === params.page)

            // si on crée une page mais qu'il n'y a pas encore de form, on false, sinon ca créer une erreur
            if (!getElements) return false;

            var directives = getElements['list'];

            for (var field in directives) {

                if (Object.keys(directives[field]).length > 0 && field == params.item.cle) {


                    let modifyActions = [];
                    // pour chaque champs qui peuvent être modifiés  par rapport au champ qui détient les directives, 
                    //on boucle des directives de chacun

                    directives[field].forEach(function(directive) {


                        directive.then.forEach(function(act) {
                            var getReturn = []
                            directive.if.forEach(function(cond) {
                                var aaa = false
                                switch (cond.type) {
                                    case 'value':
                                        if (cond.value == listFields[field]) {
                                            aaa = true
                                        }
                                        break;
                                    case 'like':
                                        if (listFields[field].indexOf(cond.value) != -1) {
                                            aaa = true

                                        }
                                        break;
                                    case '*':
                                        if (listFields[field] != '') {
                                            aaa = true
                                        }
                                        break;
                                    case 'null':
                                        if (listFields[field] == '') {
                                            aaa = true
                                        }
                                        break;
                                    case 'istrue':
                                        if (listFields[field] === true) {
                                            aaa = true
                                        }
                                        break;
                                    case 'isfalse':
                                        if (listFields[field] === false) {
                                            aaa = true
                                        }
                                        break;
                                    case 'issupp':

                                        if (Date.parse(listFields[field]) > Date.parse(listFields[cond.value]) ||
                                            listFields[field] > listFields[cond.value]) {

                                            aaa = true
                                        }
                                        break;
                                    case 'isinf':
                                        if (Date.parse(listFields[field]) < Date.parse(listFields[cond.value]) ||
                                            listFields[field] < listFields[cond.value]) {
                                            aaa = true
                                        }
                                        break;
                                    case 'isequal':
                                        if (Date.parse(listFields[field]) == Date.parse(listFields[cond.value]) ||
                                            listFields[field] == listFields[cond.value]) {
                                            aaa = true
                                        }
                                        break;
                                    case 'iszerohour':
                                        if (moment(listFields[field]).format('HH:mm') == '00:00') {
                                            aaa = true
                                        }
                                        break;
                                }
                                getReturn.push(aaa)
                            })

                            var arr = { field: act.field, dir: [] }


                            //si l'un des champs fait l'objet d'une directive 
                            let check = getReturn.every(v => v === true);
                            if (check) {

                                act.actions.forEach(function(listact) {
                                    delete listact.descr;
                                    var action = listact

                                    if (typeof params.model == 'object' && (listact.type == 'valuefromfield' || listact.type == 'createoptionsfromarray')) {
                                        if (listact.value == "AllArray") action.value = params.model
                                        if (listact.value != "AllArray") action.value = params.model[listact.value];
                                    }
                                    arr.dir.push(action)
                                })

                                modifyActions.push(arr)
                            } else {
                                // si l'un des champs faisant l'object d'une directive n'est plus sujet a celle-ci,
                                // on enlève ce qu'il avait
                                getElements.current[act.field] = []
                            }

                        })

                    })



                    if (modifyActions.length > 0) {

                        modifyActions.forEach((v) => {
                            if (v.dir.length > 0) {
                                v.dir.forEach((direct) => {

                                    getElements.current[v.field] = getElements.current[v.field].filter(x => {
                                            return JSON.stringify(x) != JSON.stringify(direct);
                                        })
                                        // on fait ca pour que le watch fonctionne dans Fields.js
                                    getElements.current[v.field].push(direct)



                                })

                            }

                        })

                    }
                }
            }

        }



    },

    actions: {
        // on met le scripte directement dans l'action car on peut appeler une autre action dans l'action. pas possible dans les mutations
        initConditions: function({ state, dispatch, getters }, arrayField) {

            var SpecCond = { Formulaire: arrayField.page, list: {}, current: {} }

            arrayField.datas.forEach(function(field) {

                SpecCond['list'][field.cle] = []
                SpecCond['current'][field.cle] = []
                if (field.directives.length > 0) {
                    field.directives.forEach(function(directive) {
                        SpecCond['list'][field.cle].push(directive)
                    })

                }
            })

            if (!state.specificCondition.find(specific => specific.Formulaire === arrayField.page)) {
                state.specificCondition.push(SpecCond)
            } else {
                let ee = state.specificCondition.find(specific => specific.Formulaire === arrayField.page)

                ee['list'] = {...ee['list'], ...SpecCond['list'] }
                ee['current'] = {...ee['current'], ...SpecCond['current'] }

            }
            var launchDirective = [];
            arrayField.datas.forEach(function(field) {
                if (field.directives.length > 0) {
                    field.directives.forEach(function(directive) {
                        directive.then.forEach((act) => {
                            if (act.actions.find(el => el.firstload === true)) launchDirective.push(field)
                        })
                    })
                }
            })
            launchDirective.forEach((field) => {
                let dom = { page: arrayField.page, item: field, model: getters.listForm(arrayField.page)['fields'][field.cle], first: true };
                dispatch('applySpecificCondition', dom)
            })

        },

        applySpecificCondition({ commit, getters }, params) {
            commit('applySpecificCondition', { params, getters })
        }


    }

}
export default conditionForm;