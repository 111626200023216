<template>
    <div>
    {{item.name}}    
    <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">




        <v-text-field
                v-model="translateResult"
                @click:append="menu = true"
                v-bind="attrs"
                readonly
                v-on="on"
                dense
    
                :rules="prop.modelRules"
                v-if="prop.show"
                :outlined="prop.outlined"
                :filled="prop.filled"
                :solo="prop.solo"
                :hint="prop.description"
                :persistent-hint="prop.persistenthint"
                :disabled="prop.disabled"
                :class="prop.class"
                :clearable="prop.clearable"
                :color="prop.coloronselect"
                @click:clear="model = '-'"
                :prepend-icon="(prop.iconlr=='prepend' && prop.iconinout=='out') ? prop.iconmdi : ''"
                :prepend-inner-icon="(prop.iconlr=='prepend' && prop.iconinout!='out') ? prop.iconmdi : ''"
                :append-icon="(prop.iconlr=='append' && prop.iconinout!='out') ? prop.iconmdi : ''"
                :append-outer-icon="(prop.iconlr=='append' && prop.iconinout=='out') ? prop.iconmdi : ''" 
    
              ></v-text-field>




            </template>
              <v-card>
              <v-btn
              text
              color="primary"
              @click="menu = false"
              >
              Annuler
              </v-btn>
              <v-btn
              text
              color="primary"
              @click="set"
              >
              OK
              </v-btn>
              <v-spacer></v-spacer>
              <div class="text-center">{{ translateResult }}</div>
                    <v-tabs v-model="tabs">
                    <v-tabs-slider color="red darken-4"></v-tabs-slider> 
                    <v-tab href="#date">date</v-tab>
                    <v-tab href="#heure">heure</v-tab>

                        <v-tab-item id="date">
                            <v-date-picker
                            v-model="modelDate"
                            locale="fr"
                            no-title
                            scrollable
                            >
                            </v-date-picker>
                        </v-tab-item>

                        <v-tab-item id="heure">
                            <v-time-picker
                            v-model="modelTime"
                            :light="true"
                            locale="fr"
                            no-title
                            format="24hr"
                            ></v-time-picker>
                        </v-tab-item>

                    </v-tabs>     
              </v-card>
          </v-menu>

    </div>
    </template>
    
    
    
    <script>
    
    import mixinFields from '../../mixins/Fields'
    
        export default {
            name : 'datePicker',
    
            mixins : [mixinFields],
    
            data: function() {
                return { 
                    translateResult : '',
                    menu : false,
                    modelTime : '00:00',
                    modelDate : '0000-00-00',
                    tabs : 'date',
                    loaded : false
                }
            },
    
            computed: {
    
            },
    
            watch : { 

              menu(val) {
                if(val) this.tabs = 'date'
              },

               valField(val) {
                this.setDate(val)
               },

               modelDate(val) {
                if(!this.loaded) return false;
                let newDate = this.moment(val+' '+this.modelTime+':00').format('YYYY-MM-DD HH:mm:00');
                this.tabs = 'heure'
                this.change(this.item,newDate)
                this.changeStoreOnField({item:this.item,model:newDate,page:this.page}) 

               },
    
            },
    
            methods : {

              setDate(val) {

                if(val=='0000-00-00 00:00:00') {
                  this.translateResult = 'Choisir une date';
                } else {
                  this.translateResult = this.moment(val).format('DD/MM/YYYY HH:mm');
                  this.modelDate = this.moment(val).format('YYYY-MM-DD')
                  this.modelTime = this.moment(val).format('HH:mm')
                }
                this.loaded = true                
              },

              set() {
                let newDate = this.moment(this.modelDate+' '+this.modelTime+':00').format('YYYY-MM-DD HH:mm:00');
                this.change(this.item,newDate)
                this.changeStoreOnField({item:this.item,model:newDate,page:this.page}) 
                this.menu = false
              }

    
            },

            mounted() {
              this.setDate(this.valField)
            }
    
        }
    
    </script>
    