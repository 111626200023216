<template>
  <div>
    <v-container v-if="checkDroits">
      <v-row>
        <v-col class="text-h5 text-center">Gestion des Bases de Données</v-col>
      </v-row>

      <v-tabs>
        <v-tab>Créer une BDD entreprise</v-tab>
        <v-tab>Créer un Métier</v-tab>
        <v-tab>Créer une nouvelle table</v-tab>

        <v-tab-item>
          <v-row>
            <v-col>
              <div class="text-h5">Créer une BDD entreprise :</div>
              <v-form v-model="validForm" ref="databaseForm">
                <v-row>
                  <v-col>
                    <TextField
                      :item="{
                        value: 'entrepriseName',
                        name: 'Nom de l\'entreprise',
                        defaultprops: {
                          show: true,
                          modelRules: [
                            (v) => !!v || 'Ce champ ne doit pas être vide',
                          ],
                          outlined: true,
                          required: true,
                        },
                      }"
                      :keyup="keyUp"
                      :valField="form.entrepriseName"
                    />
                    <Select
                      :change="change"
                      :item="{
                        name: 'Métier attribué',
                        value: 'databaseType',
                        liste: databaseTypes,
                        defaultprops: {
                          show: true,
                          required: true,
                          modelRules: [
                            (v) => !!v || 'Ce champ ne doit pas être vide',
                          ],
                        },
                      }"
                      :valField="form.databaseType"
                    />

                    <v-btn @click="createDatabaseEnt"
                      >Créer la Base de Données</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>

              <div v-if="progressVisible">
                <div>Étape: {{ step }}</div>
                <v-progress-linear :indeterminate="true"></v-progress-linear>
              </div>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row>
            <v-col>
              <div class="text-h5">Créer un Métier :</div>
              <v-form v-model="validFormMetier" ref="metierForm">
                <v-row>
                  <v-col>
                    <TextField
                      :item="{
                        value: 'metierName',
                        name: 'Nom du métier',
                        defaultprops: {
                          show: true,
                          modelRules: [
                            (v) => !!v || 'Ce champ ne doit pas être vide',
                          ],
                          outlined: true,
                          required: true,
                        },
                      }"
                      :keyup="keyUpMetier"
                      :valField="formMetier.metierName"
                    />
                    <v-btn @click="createMetier">Créer le Métier</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row>
            <v-col>
              <div class="text-h5">
                Créer une nouvelle table dans le métier :
              </div>
              <v-form
                @submit.prevent="handleMigrationSubmit"
                v-model="validFormMigration"
                ref="migrationForm"
              >
                <v-row v-for="(table, index) in tables" :key="index">
                  <v-col>
                    <v-text-field
                      v-model="table.name"
                      label="Nom de la table"
                      :rules="[
                        (v) => !!v || 'Ce champ ne doit pas être vide',
                        (v) =>
                          !existingTables.includes(v) ||
                          'Le nom de la table existe déjà',
                      ]"
                      required
                      outlined
                    ></v-text-field>
                    <v-btn @click.prevent="addColumn(index)"
                      >Ajouter une colonne</v-btn
                    >
                    <v-row>
                      <v-col
                        v-for="(column, colIndex) in table.columns"
                        :key="colIndex"
                        cols="8"
                        sm="4"
                        md="2"
                        class="d-flex"
                      >
                        <v-card class="pa-2 flex-grow-1">
                          <v-btn
                            icon
                            small
                            @click="removeColumn(index, colIndex)"
                            class="mb-2"
                            color="red"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                          <v-text-field
                            v-model="column.name"
                            label="Nom de la colonne"
                            :rules="[
                              (v) => !!v || 'Ce champ ne doit pas être vide',
                              (v) =>
                                !isDuplicateColumnName(index, v) ||
                                'Ce nom de colonne existe déjà dans cette table',
                            ]"
                            required
                            outlined
                          ></v-text-field>
                          <v-select
                            v-model="column.type"
                            :items="columnTypes"
                            label="Type"
                            :rules="[
                              (v) => !!v || 'Ce champ ne doit pas être vide',
                            ]"
                            required
                            outlined
                          ></v-select>
                          <v-checkbox
                            v-model="column.nullable"
                            label="Nullable"
                            outlined
                          ></v-checkbox>
                          <v-text-field
                            v-model="column.default"
                            label="Valeur par défaut"
                            outlined
                          ></v-text-field>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-btn @click.prevent="addTable">Ajouter une table</v-btn>
                <v-btn type="submit">Générer les Migrations</v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
      <v-row>
        <v-col>
          <div v-if="progressVisibleMetier">
            <div>Étape: {{ stepMetier }}</div>
            <v-progress-linear :indeterminate="true"></v-progress-linear>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else>
      <v-row>
        <v-col>
          {{ isAllow }}
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "manage-bdd",
  props: ["page", "modal"],
  components: {
    TextField: () => import("../fields/textfield.vue"),
    Select: () => import("../fields/select.vue"),
  },

  data: function () {
    return {
      validForm: false,
      validFormMetier: false,
      validFormMigration: false,
      isAllow: "En attente du module",
      checkDroits: false,
      form: {
        entrepriseName: "",
        databaseType: "",
      },
      formMetier: {
        metierName: "",
      },
      databaseTypes: [],
      progressVisible: false,
      progressVisibleMetier: false,
      step: "",
      stepMetier: "",
      tables: [
        {
          name: "",
          columns: [
            {
              name: "",
              type: "string",
              nullable: false,
              default: "",
            },
          ],
          errors: [], // Nouveau champ pour stocker les erreurs
        },
      ],
      columnTypes: [
        "string",
        "text",
        "integer",
        "unsignedBigInteger",
        // Add more types as needed
      ],
      existingTables: [], // Array to hold existing tables
    };
  },

  methods: {

    keyUp(item, value) {
      this.form[item.value] = value;
    },

    keyUpMetier(item, value) {
      this.formMetier[item.value] = value;
    },

    change(item, model, selectedKey) {
      this.form[item.value] = model[selectedKey];
    },
    removeColumn(tableIndex, columnIndex) {
      this.tables[tableIndex].columns.splice(columnIndex, 1);
    },

    createDatabaseEnt() {
      if (this.$refs.databaseForm.validate()) {
        this.progressVisible = true;
        this.step = "Création de la base de données";

        this.API({
          type: "post",
          url: "/create-database",
          params: {
            entreprise: this.form.entrepriseName,
            type: this.form.databaseType,
          },
        })
          .then((resp) => {
            if (resp.data.success) {
              this.step = "Exécution des migrations et des seeders";
              this.runMigrationsAndSeeders(
                resp.data.databaseName,
                resp.data.metier
              );
            } else {
              this.$toast.error(
                "Erreur lors de la création de la base de données."
              );
              this.progressVisible = false;
            }
          })
          .catch((error) => {
            this.$toast.error("Erreur : " + error.message);
            this.progressVisible = false;
          });
      }
    },

    runMigrationsAndSeeders(databaseName, metier) {
      this.API({
        type: "post",
        url: "/run-migrations-and-seeders",
        params: {
          type: metier,
          database: databaseName,
        },
      })
        .then((resp) => {
          if (resp.data.success) {
            this.$toast.success("Migrations et seeders exécutés avec succès !");
            this.progressVisible = false;
            window.location.href = "/";
          } else {
            this.$toast.error(
              "Erreur lors de l'exécution des migrations et des seeders."
            );
            this.progressVisible = false;
          }
        })
        .catch((error) => {
          this.$toast.error("Erreur : " + error.message);
          this.progressVisible = false;
        });
    },

    createMetier() {
      if (this.$refs.metierForm.validate()) {
        this.progressVisibleMetier = true;
        this.stepMetier = "Création du métier";

        this.API({
          type: "post",
          url: "/create-metier",
          params: {
            metier: this.formMetier.metierName,
          },
        })
          .then((resp) => {
            if (resp.data.success) {
              this.$toast.success("Métier créé avec succès !");
              this.runMigrationsAndSeedersMetier(resp.data.metierName);
            } else {
              this.$toast.error("Erreur lors de la création du métier.");
              this.progressVisibleMetier = false;
            }
          })
          .catch((error) => {
            this.$toast.error("Erreur : " + error.message);
            this.progressVisibleMetier = false;
          });
      }
    },

    runMigrationsAndSeedersMetier(metierName) {
      this.progressVisibleMetier = true;
      this.stepMetier =
        "Exécution des migrations et des seeders pour le métier";

      this.API({
        type: "post",
        url: "/run-migrations-and-seeders-metier",
        params: {
          metierName: metierName,
        },
      })
        .then((resp) => {
          if (resp.data.success) {
            this.$toast.success(
              "Migrations et seeders pour le métier exécutés avec succès !"
            );
            this.createDatabaseDemo(metierName);
          } else {
            this.$toast.error(
              "Erreur lors de l'exécution des migrations et des seeders pour le métier."
            );
            this.progressVisibleMetier = false;
          }
        })
        .catch((error) => {
          this.$toast.error("Erreur : " + error.message);
          this.progressVisibleMetier = false;
        });
    },

    createDatabaseDemo(metierName) {
      this.progressVisibleMetier = true;
      this.stepMetier =
        "Création de l'entreprise 'demo' pour le nouveau métier";

      this.API({
        type: "post",
        url: "/create-database",
        params: {
          entreprise: "demo",
          type: metierName,
        },
      })
        .then((resp) => {
          if (resp.data.success) {
            this.stepMetier = "Exécution des migrations et des seeders";
            this.runMigrationsAndSeeders(
              resp.data.databaseName,
              resp.data.metier
            );
          } else {
            this.$toast.error(
              'Erreur lors de la création de l\'entreprise "demo".'
            );
            this.progressVisibleMetier = false;
          }
        })
        .catch((error) => {
          this.$toast.error("Erreur : " + error.message);
          this.progressVisibleMetier = false;
        });
    },

    // New methods for migration form
    addTable() {
      this.tables.push({
        name: "",
        columns: [
          {
            name: "",
            type: "string",
            nullable: false,
            default: "",
          },
        ],
        errors: [], // Ajouter un tableau d'erreurs pour chaque nouvelle table
      });
    },

    addColumn(tableIndex) {
      this.tables[tableIndex].columns.push({
        name: "",
        type: "string",
        nullable: false,
        default: "",
      });
    },

    isDuplicateColumnName(tableIndex, columnName) {
      const columnNames = this.tables[tableIndex].columns.map(
        (column) => column.name
      );
      return columnNames.filter((name) => name === columnName).length > 1;
    },

    async handleMigrationSubmit() {
      if (this.$refs.migrationForm.validate()) {
        this.progressVisibleMigration = true;
        this.stepMigration = "Génération des migrations";

        this.API({
          type: "post",
          url: "/generate-migrations",
          params: {
            tables: this.tables,
          },
        })
          .then((resp) => {
            if (resp.data.success) {
              this.$toast.success(resp.data.message);
              this.progressVisibleMigration = false;
            } else {
              this.$toast.error(resp.data.message);
              this.progressVisibleMigration = false;
            }
          })
          .catch((error) => {
            this.$toast.error("Erreur : " + error.message);
            this.progressVisibleMigration = false;
          });
      } else {
        this.$toast.error("Veuillez corriger les erreurs avant de soumettre.");
      }
    },

    async loadExistingTables() {
      try {
        const response = await this.API({
          type: "post",
          url: "/list-tables",
        });
        this.existingTables = response.data.tables.map(
          (table) => Object.values(table)[0]
        );
      } catch (error) {
        this.$toast.error(
          "Erreur lors du chargement des tables existantes : " + error.message
        );
      }
    },

    async loadDatabaseTypes() {
      try {
        const response = await this.API({
          type: "post",
          url: "/list-metiers",
        });
        this.databaseTypes = response.data.metiers;
      } catch (error) {
        this.$toast.error(
          "Erreur lors du chargement des types de base de données : " + error.message
        );
      }
    },
  },

  created() {
    // on check si l'utilisateur peut créer une base de données
    this.API({
      type: "post",
      url: "/engine/droits/getdroit",
      params: { root: "general", core: "managebdd" },
    }).then((resp) => {
      this.checkDroits = resp.data;
    });

    // Load existing tables
    this.loadExistingTables();
    this.loadDatabaseTypes();
  },
};
</script>
