<template>
    <div v-if="show">


        <v-btn class="primary" :class="datas.button.class" v-for="(mail,i) in listMails" :key="i" @click="SelectMail(mail)"><v-icon>mdi-email-outline</v-icon> {{ mail.titre }}</v-btn>

        <v-dialog
        :retain-focus="false"
        width="50%" 
        v-model="dialog"
        :fullscreen="$vuetify.breakpoint.xs">
        <OverLay/>
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Envoi de l'email
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog()"><v-icon>mdi-close-thick</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <p class="text-center text-h6">Expéditeur : {{ params.mailfrom.name }} ( {{ params.mailfrom.from }} )</p>
                        <ArrayTable 
                            :item="{otherparams:{
                                arrayTable:[
                                    {type:'',key:'Name',name:'alias'},                           
                                    {type:'',key:'Email',name:'adresse e-mail'}]}
                            ,defaultprops:{show:true,tableDraggable:false,tableDel:false,hideFooter:true,hidesearch:true,addTitle:'ajouter une adresse'}}"
                            :valField="params.emails"
                            :change="(item,model)=> { changeParams(item,model,'emails') }"
                            />

                    <TextField
                    :item="{ name: 'Objet', value: 'objet' ,defaultprops:{ show: true }}"
                    :valField="params.selectedMail.objet"
                    :keyup="(item,model)=> { changeMail(item,model,'objet') }"
                    /> 
                    Pièces jointes :
                    <div v-if="params.attachements.length && params.selectedMail.id">
                        <div v-for="(attach,i) in params.attachements" :key="i" :class="['rounded pa-2 grey lighten-3 dark',{'text-decoration-line-through':!params.includeAttachement[params.selectedMail.id][attach.mod]}]">
                            <v-btn icon @click="includePJFile(attach,false)" v-if="params.includeAttachement[params.selectedMail.id][attach.mod]" class="red--text"><v-icon>mdi-close-thick</v-icon></v-btn>
                            <v-btn icon @click="includePJFile(attach,true)" v-if="!params.includeAttachement[params.selectedMail.id][attach.mod]" class="green--text"><v-icon>mdi-refresh</v-icon></v-btn>
                            {{ attach.name }}
                        </div>
                    </div>
                    <div v-for="(file,i) in $store.getters['getGedFile']" :key="'g'+i" class="rounded pa-2 grey lighten-3 dark">
                        <v-btn icon @click="$store.dispatch('removeGedFile',file)" class="red--text"><v-icon>mdi-close-thick</v-icon></v-btn> 
                        {{ file.path.split("/").pop() }} (GED)
                        </div> 

                            <div v-if="isGed.id">
                                <v-btn @click="gotoGed" icon class="primary dark"><v-icon color="white">mdi-file-download-outline</v-icon></v-btn>
                                <v-dialog
                                :retain-focus="false"
                                width="50%" 
                                v-model="dialogged"
                                @click:outside="$store.dispatch('removeAllGedFile')"
                                :fullscreen="$vuetify.breakpoint.xs">
                                <v-card>
                                    <v-card-title class="text-h6 grey lighten-2">
                                        Documents
                                    <v-spacer></v-spacer>
                                    <v-btn icon @click="closeGed"><v-icon>mdi-close-thick</v-icon></v-btn>      
                                    </v-card-title>
                                <ged-component v-if="dialogged" :idmodule="isGed.id" :page="page" :modal="modal" :parent="parent" :customparams="isGed.params"/>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn @click="dialogged=false">intégrer</v-btn>
                                </v-card-actions>
                                </v-card>
                                </v-dialog>
                            </div>
                            
                        <div v-if="fieldForUpdate">
                            <fromBDD 
                            :item="{fromBDD:fieldForUpdate,defaultprops:{show:true}}"
                            :page="page"
                            :valField="params.selectedMail.corp"
                            :keyup="(item,model)=> { changeMail(item,model,'corp') }"
                            /> 
                        </div>
                        <div v-else>
                            <texteditor
                            :page="page"
                            :item="{name: 'corp du mail',value: 'corp',
                            
                            defaultprops:{ show: true}
                            }"
                            :keyup="(item,model)=> { changeMail(item,model,'corp') }"
                            :valField="params.selectedMail.corp"
                            />
                        </div>

                    </v-col>

                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn @click="send">envoyer</v-btn>
                        </v-col>
                    </v-row>
                    </v-card-text>
                </v-card>

            </v-dialog>
        </div>
</template>
        
        
        
<script>
import fromBDD from '../fields/fromBDD.vue'
export default {

    name: "sendEmail",

    props: ['idmodule', 'page', 'modal', 'parent', 'hook','customparams','customquerys'],

    components: {
        texteditor : () => import("../fields/texteditor.vue"),
        TextField: () => import("../fields/textfield.vue"),
        ArrayTable: () => import("../fields/arrayTable.vue"),
        OverLay: () => import('../outils/overlay.vue'),
        fromBDD
    },

    data: function () {

        return {
            AllowedDisplay: true,
            show: false,
            datas: {},
            params: { selectedMail : {},emails : [],send:false,attachements : [], mailfrom : {},includeAttachement : {}},
            querys: {},
            dialog: false,
            dialogged : false,
            fieldForUpdate : false,
            listMails : [],
            isGed: {},
            typePage : (!this.$store.getters['listForm'](this.page)) ? 'page' : this.$store.getters['listForm'](this.page).typeform,
        }
    },

    computed : {
            modEditForm() {
                return this.$store.getters['listForm'](this.page);
            }
    },

    watch: {

        'modEditForm.typeform': {
                // permet de rafraichir quand la modal de modif du module de la page se ferme
                handler(newVal,oldVal) {
                    if(oldVal!='new') return;
                        this.typePage = newVal
                        this.initiateParams()
                        this.getModule()
                },deep:true,
            } 
    },

    methods: {

        getModule: function () {
            if(this.typePage=='new') return
            this.API({ type: 'post', url: 'displaymodule', params: { id: this.idmodule, params: this.params, querys: this.querys } }).then((resp) => {
                this.datas = resp.data;
                if (!resp.data.success) {
                    this.AllowedDisplay = false;
                    this.$toast.error(resp.data.message)
                    return false;
                }
                this.listMails = resp.data.listmails;
                
                    this.params.emails = resp.data.emails
                    this.params.mailfrom = resp.data.mailfrom
                    this.params.attachements = resp.data.attachements
                    this.params.includeAttachement = resp.data.includeAttachement
                    this.isGed = resp.data.ged;
                    if(resp.data.idField) this.fieldForUpdate = resp.data.idField                


                this.show = true

                if(this.querys.selected && this.listMails[this.querys.selected]) {

                    this.SelectMail(this.listMails[this.querys.selected]);
                }

            })

        },

        changeParams(item, value, type) {
            this.params[type] = value
        },

        changeMail(item, value, type) {
            this.params.selectedMail[type] = value
        },

        send() {
            this.$store.dispatch('textOverlay','Envoi du mail en cours');
            this.$store.dispatch('getoverlay', true)
            this.params.send = true
            this.API({ type: 'post', url: 'displaymodule', params: { id: this.idmodule, params: this.params, querys: this.querys, gedfiles:this.$store.getters['getGedFile'] } }).then((resp) => {
                this.$store.dispatch('getoverlay', false)

                if (!resp.data.success) {
                    this.$toast.error(resp.data.message)
                    return false;
                }
                this.$toast.success("l'email est bien envoyé !")
                this.dialog = false;
                this.$store.dispatch('removeAllGedFile');
                })            
        },

        initiateParams() {
        var modal = this.$store.getters.modalShowParams({ type: 'to', val: this.page })
        let params = (this.modal && modal.show) ? modal.params : this.$route.params
        params = (this.customparams) ? this.customparams : params;
        this.params = {...this.params, ...params}

        this.querys = (this.modal && modal.show) ? modal.query : this.$route.query
            
        this.querys = (this.customquerys) ? this.customquerys : this.querys;
        if (this.hook == 'search') this.querys.global = this.$store.getters.getStringSearch;
        },

        gotoGed() {
            this.isGed.params.select = true;
            this.dialogged = true;
        },

        closeGed() {
            this.$store.dispatch('removeAllGedFile'); 
            this.dialogged=false;
        },

        SelectMail(mail) {

            this.params.selectedMail = mail
            this.dialog = true
        },

        closeDialog() {
            this.$store.dispatch('removeAllGedFile');
            this.dialog = false
            this.params.selectedMail = {}
        },

        includePJFile(attach,isinclude) {
            this.params.includeAttachement[this.params.selectedMail.id][attach.mod] = isinclude;
        }


    },
    mounted() {
        this.initiateParams()
        this.getModule()
        

    },

    created() {

    }

}

</script>
    
    
<style>
.cropper {
    height: 500px;
    background: #DDD;
}
</style>
        