<template>
<v-form 
    ref="form"
    v-model="valid"
    lazy-validation
>

            <v-row no-gutters>
                <v-col>
                    <v-row>
                    <v-col class="col-md-6">
                    <TextField 
                    :item="{name:'Nom du bouton d\'action',value:'name',defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide']}}" 
                    :keyup="updateParams"
                    :valField="params.name" />
                        
                    
                    <TextField 
                    v-if="params.params"
                    :item="{name:'Texte de confirmation avant action',value:'ifconfirm',defaultprops:{show:true}}" 
                    :keyup="updateDeepParams"
                    :valField="params.params.ifconfirm" /> 

                    <Select 
                    :valField="params.typelink" 
                    :change="updateParams"
                    :item="{name:'que souhaitez vous faire ?',
                    value:'typelink',
                    liste:[{text:'effectuer une action sur l\'API',value:'api'},{text:'envoi sur une page',value:'link'},{text:'lien href classique',value:'href'}],
                    defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide']}}" />

                    <Select 
                    :valField="params.params.apilink.type" 
                    :change="updateAPIlink"
                    v-if="params.typelink=='api'"
                    :item="{name:'type d\'envoi',
                    value:'type',
                    liste:[{text:'GET',value:'get'},{text:'POST',value:'post'},{text:'PUT',value:'put'}],
                    defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide']}}" />

                    <TextField 
                    v-if="params.typelink=='api' || params.typelink=='href'"
                    :item="{name:'URL',value:'url'
                    ,defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide'],
                    description:'Vous pouvez placer la variable d\'envirronnement \'table\' pour cibler les valeurs du tableau',persistenthint:true }}" 
                    :keyup="updateAPIlink"
                    :valField="params.params.apilink.url" />

                    <SwitchC
                    v-if="params.typelink=='api'"
                    :item="{name:'placer en entete de tableau (visible si une sélection est faite)',value:'head',
                    defaultprops:{show:true,description:'modifiez la valeur du tableau \'[[table||id]]\' par \'selected\' dans l\'URL afin de cibler toutes les lignes sélectionnées ',
                    persistenthint:true}}" 
                    :change="updateDeepParams"
                    :valField="params.params.head" />

                    <ListMultiple 
                    v-if="params.typelink=='link'"
                    :change="updateParams"
                    :item="{name:'Envoi sur la page :',value:'page_name',liste:listePages,
                    defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide']}}" 
                    :valField="params.page_name" />
                    </v-col>

                    <v-col class="col-md-6">

                    <ArrayTable 
                    :item="{otherparams:{
                        arrayTable : urlParams }
                    ,defaultprops:{show:true,tableDraggable:false,tableDel:true,hideFooter:true,hidesearch:true,addTitle:'ajouter un param. URL'}}"
                    :valField="params.get"
                    :change="updateGet"
                    />

                    <ArrayTable 
                    v-if="params.params"
                    :item="{otherparams:{
                        arrayTable:[
                            {type:'',key:'key',name:'clé du champ'},
                            {type:'select',key:'type',name:'provenance',
                                ArrayValue:[
                                    {text:'Des résultats disponibles du module',value:'table'},
                                    {text:'libre',value:'free'}]},
                            {type:'select',key:'operation',name:'est',
                                ArrayValue:[
                                    {text:'égal à',value:'='},
                                    {text:'différent de',value:'!='}]},    
                            {type:'select',key:'typedata',name:'type de donnée',
                                ArrayValue:[
                                    {text:'nombre',value:'number'},
                                    {text:'texte',value:'text'},
                                    {text:'booleen',value:'bool'}]},                                
                            {type:'',key:'value',name:'valeur'}
                        ]}
                    ,defaultprops:{show:true,tableDraggable:false,tableDel:true,hideFooter:true,hidesearch:true,addTitle:'ajouter une condition d\'affichage'}}"
                    :valField="params.params.conditions"
                    :change="updateConditions"
                    />


                    <Select 
                    :valField="params.target" 
                    v-if="params.typelink=='link'"
                    :change="updateParams"
                    :item="{name:'Type d\'ouverture',value:'target',liste:[{text:'mode dialog',value:'modal'},{text:'en centre',value:'center'}]
                    ,defaultprops:{show:true,modelRules:[v => !!v || 'Ce champ ne doit pas être vide']}}" />

                    <TextField 
                    :valField="params.icon" 
                    :keyup="updateParams"
                    :item="{name:'Icone',value:'icon',defaultprops:{show:true}}" /> 
                    </v-col>
                    </v-row>
                    <div v-if="params.target=='modal' && params.typelink=='link'">

                        <paramDialog :page="page" :params="params.paramdialog" @returnParamDialog="ChangeParamDialog"/>                                           
                    </div>
                
                </v-col>
            </v-row>
    <v-row>
        <v-col class="col-12 text-center">
            <v-btn class="primary" @click="validate" :disabled="!valid">Enregistrer</v-btn>
        </v-col>
    </v-row>
</v-form>

</template>



<script>

    export default {
        name : 'EditAction',
        props : ['page','modal'],

        components : {
            TextField   : () => import('../../fields/textfield.vue'),
            Select      : () => import('../../fields/select.vue'),
            ListMultiple   : () => import('../../fields/autocomplete.vue'),
            ArrayTable    : () => import('../../fields/arrayTable.vue'),
            SwitchC: () => import("../../fields/switch.vue"),
            paramDialog: () => import("../outils/paramDialog.vue"),
        },

        data: function() {
            return { 
                urlSend : '',
                valid : false,
                params : {},
                listePages : [],
                urlParams : [
                            {type:'',key:'key',name:'clé dans l\'url'},
                            {type:'select',key:'type',name:'provenance',
                                ArrayValue:[
                                    {text:'Des résultats disponibles du module',value:'table'},
                                    {text:'libre',value:'free'}]},
                            {type:'select',key:'typevar',name:'typ de variable',
                                ArrayValue:[
                                    {text:'params',value:'params'},
                                    {text:'query',value:'query'}]},                                    
                            {type:'select',key:'typedata',name:'type de donnée',change:this.changeType,
                                ArrayValue:[
                                    {text:'nombre',value:'number'},
                                    {text:'texte',value:'text'},
                                    {text:'booleen',value:'bool'},
                                    {text:'array',value:'array'}]},

                            {type:'',key:'value',name:'valeur du tableau',
                            defaultprops:{show:true,tableDraggable:false,tableDel:true,hideFooter:true,hidesearch:true,addTitle:'ajout du paramètre'},
                            otherparams:{
                                  arrayTable:[
                                        {type:'',key:'key',name:'clé dans l\'url'},  
                                        {type:'select',key:'type',name:'provenance',
                                            ArrayValue:[
                                                {text:'Des résultats disponibles du module',value:'table'},
                                                {text:'libre',value:'free'}]},                                 
                                        {type:'select',key:'typedata',name:'type de donnée',
                                            ArrayValue:[
                                                {text:'nombre',value:'number'},
                                                {text:'texte',value:'text'}]},
                                        {type:'',key:'value',name:'valeur'},
                                  ]
                                }
                            }
                        ]

            }
        },


        methods : {

            changeType(item,value) {
                
                if(value.value=='array') {
                    this.urlParams.find((e)=> e.key === 'value').type='arraytable'
                } else {
                    this.urlParams.find((e)=> e.key === 'value').type=''
                    this.urlParams.find((e)=> e.key === 'value').name='Valeur'                    
                }
                

            },

            updateGet(item,value) {
                console.log(item)
                this.params.get = value;
                this.$store.dispatch('pushForm', { page:this.page, champ:'get', value:value});
            },

            updateConditions(item,value) {
                this.params.params.conditions = value;
                this.$store.dispatch('pushForm', { page:this.page, champ:'params', value:this.params.params});
            },

            validate() {
                if(this.$refs.form.validate()) {

                    this.$store.dispatch('getoverlay',true)
    
                    this.API({type:'put', url:this.urlSend, params:this.params}).then((resp) => {

                        this.$store.dispatch('getoverlay',false)
                        if(resp.data.success) {
                            this.$store.dispatch('closeModal',{page:this.$store.getters.modalShowParams({type:'to',val:this.page}).from})
                        }

                        }) 
                }

            },

            getActionFromAPI() {
                this.API({type:'get', url:this.urlSend, params:{}}).then((resp) => {                         
                        this.params = resp.data
                        this.$store.dispatch('pushFirstForm', { page:this.page, values:this.params});
                        }
                  
                    )

            },

            updateAPIlink(item,model,selectedKey) {
                var realValue = (typeof model === 'object') ? model[selectedKey] : model
                this.params.params.apilink[item.value] = realValue
            },

            updateDeepParams(item,model,selectedKey) {
                var realValue = (typeof model === 'object') ? model[selectedKey] : model
                this.params.params[item.value] = realValue
            },

            updateParams(item,model,selectedKey) {
                var realValue = (typeof model === 'object') ? model[selectedKey] : model
                this.params[item.value] = realValue
                if(item.value=='type' && realValue=='modal') {
                    this.params.paramdialog = {};
                    this.$store.dispatch('pushForm', { page:this.page, champ:'paramdialog', value:{} });
                }

                if(item.value=='page_name' && realValue!='') {
                    var url = this.listePages.find(el => el.value === realValue).url
                    console.log(url)
                    if(url.indexOf(':')>-1) {
 
                        var res = url.split('/')
                        res.forEach((v) => {
                            console.log(v)
                            if(v.indexOf(':')>-1) {
                                var key = v.replace(':','')
                                if(!this.params.get.find(el => el.key === key)) {                                   
                                    this.params.get.push({key:key,type:'table',value:key})
                                }
                            }

                        }) 

                    }

                }
                this.$store.dispatch('pushForm', { page:this.page, champ:item.value, value:model});
            },

            ChangeParamDialog(data) {
                this.params.paramdialog = data

            },

        },
    
        mounted() {         
           
            this.urlSend = (!this.modal) ? this.$route.path : this.$store.getters.modalShowParams({type:'to',val:this.page}).url
            this.getActionFromAPI()

                var that = this
                this.$store.getters['listAll'].forEach(function(page){
                    that.listePages.push({text:page.displayname+" ("+page.path+") ",value:page.name,url:page.path})
                })
        }

    }

</script>

